import { CompleteUserInfoProps, CompleteUserInfoValues } from './CompleteUserInfo.types';
import { Field, FormikProps } from 'formik';
import { OptionalField } from '../../shared/ServiceSlots/RequestWaitingRoomModal/RequestWaitingRoomDataComponents/OptionalField';
import React from 'react';
import { PhoneInput } from '../../shared/PhoneInput';
import { useTranslation } from 'react-i18next';
import {
    Button,
    CheckBox,
    CheckBoxErrorIcon,
    CheckBoxLabel,
    ErrorMessage,
    FormAsyncSelect,
    FormSelect,
    H1,
    Input,
    Option,
    UnderlineH2,
} from '../../../medrefer-web-sdk/web-kit';
import { useInsuranceStatusOptions } from '../../../hooks/options/useInsuranceStatusOptions';
import useLocationAutocompleteOptions from '../../../hooks/options/useLocationAutocompleteOptions';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { PrivacyPolicyNote } from '../../PrivacyPolicy';
export const CompleteUserInfoData = (props: CompleteUserInfoProps & FormikProps<CompleteUserInfoValues>) => {
    const { t } = useTranslation();
    const insuranceStatusOptions = useInsuranceStatusOptions();
    const { loadLocationAutocompleteOptions, setAddressProps } = useLocationAutocompleteOptions();

    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                props.handleSubmit();
            }}
        >
            <H1 className="mb-3">{t('slotBooking:complete_user_info_title')}</H1>
            <Row>
                <Col>
                    <UnderlineH2>{t('slotBooking:complete_user_info_description')}</UnderlineH2>
                </Col>
            </Row>
            {!props.values.phone_valid && (
                <OptionalField
                    isError={!!(props.errors.phone_number && props.touched.phone_number)}
                    errorMessage={props.errors.phone_number}
                    fieldName={'phone'}
                    fieldLabel={t('newAppointmentForm:title_phone_number')}
                    fields={props.values.fields}
                    mandatory_fields={props.values.mandatory_fields}
                >
                    <PhoneInput
                        isError={!!(props.touched.phone_number && props.errors.phone_number)}
                        id="phone_number"
                        type="text"
                        value={props.values.phone_number}
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                    />
                </OptionalField>
            )}
            {!props.values.address_valid && (
                <OptionalField
                    isError={!!(props.errors.street && props.touched.street)}
                    errorMessage={props.errors.street}
                    fieldName={'address'}
                    fieldLabel={t('newAppointmentForm:title_address')}
                    fields={props.fields}
                    mandatory_fields={props.mandatory_fields}
                >
                    <Field
                        name="address"
                        isError={!!(props.errors.street && props.touched.street)}
                        loadOptions={loadLocationAutocompleteOptions}
                        component={FormAsyncSelect}
                        placeholder={t('adminPanel:manageLocations.locationsForm.address_placeholder')}
                        debounceTimeout={400}
                        minSearchLength={3}
                        onBlur={props.handleBlur}
                        afterChange={(option: Option) => setAddressProps(props.setFieldValue, option)}
                    />
                </OptionalField>
            )}
            {!props.values.insurance_status_valid && (
                <OptionalField
                    isError={!!(props.touched.insurance_status && props.errors.insurance_status)}
                    errorMessage={props.errors.insurance_status}
                    fieldName={'insurance_status'}
                    fieldLabel={t('newAppointmentForm:title_insurance_status')}
                    fields={props.fields}
                    mandatory_fields={props.mandatory_fields}
                >
                    <Field
                        name="insurance_status"
                        options={insuranceStatusOptions}
                        id="insurance_status"
                        component={FormSelect}
                        placeholder={t('newAppointmentForm:placeholder_insurance_status')}
                        isMulti={false}
                        type={'text'}
                        isError={props.touched.insurance_status && props.errors.insurance_status}
                        onBlur={props.handleBlur}
                    />
                </OptionalField>
            )}
            {!props.values.insurance_number_valid && (
                <OptionalField
                    isError={!!(props.touched.insurance_number && props.errors.insurance_number)}
                    errorMessage={props.errors.insurance_number}
                    fieldName={'insurance_number'}
                    fieldLabel={t('newAppointmentForm:title_insurance_number')}
                    fields={props.fields}
                    mandatory_fields={props.mandatory_fields}
                >
                    <Input
                        isError={!!(props.touched.insurance_number && props.errors.insurance_number)}
                        id="insurance_number"
                        type="text"
                        onChange={props.handleChange}
                        value={props.values.insurance_number}
                        onBlur={props.handleBlur}
                    />
                </OptionalField>
            )}
            <Row>
                <Col className="d-flex align-items-start" xs={12}>
                    <CheckBox
                        isError={props.touched.terms_agreement && props.errors.terms_agreement}
                        id="terms_agreement"
                        disable
                        checked={props.values.terms_agreement}
                        onChange={props.handleChange}
                        className="mr-4"
                    />
                    <CheckBoxLabel isRequired>
                        <PrivacyPolicyNote />
                    </CheckBoxLabel>
                    {props.touched.terms_agreement && props.errors.terms_agreement && (
                        <CheckBoxErrorIcon style={{ marginTop: -8 }} />
                    )}
                </Col>
            </Row>
            {props.touched.terms_agreement && props.errors.terms_agreement && (
                <ErrorMessage className="text-left mr-5 pr-5 mt-1">{props.errors.terms_agreement}</ErrorMessage>
            )}
            <Container style={{ marginTop: 20 }}>
                <Row>
                    <Col className="ml-auto mr-auto" xs={12} md={6}>
                        <Button type="submit">{t('newAppointmentForm:button_confirm_message')}</Button>
                    </Col>
                </Row>
            </Container>
        </Form>
    );
};
